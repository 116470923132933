<template>
  <!-- <div class="test">
    <div class="btn-box">
      <div class="btn"></div>
    </div>
    <div class="bor-box">
      <div class="over">
        <model-viewer src="http://wallet.onemoredao.com/system/nft/glb/zhengchenggong/jin.glb" alt="A 3D model of an astronaut" shadow-intensity="1" camera-controls auto-rotate ar></model-viewer>
      </div>
    </div>
  </div> -->
  <!-- <div class="box1">
    <div class="right">
      <a v-for="item in textList" :key="item.id" :href="`#box-${item.id}`">
        <div class="item">{{ item.text }}</div>
      </a>
    </div>
    <div class="left">
      <div class="item" :id="`box-${item.id}`" v-for="item in textList" :key="item.id">{{ item.text }}</div>
    </div>
  </div> -->
  <div class="box2">
    <canvas id="canvas" width="350" height="350"></canvas>
    <!-- <img src="../../assets/img/img16.png" alt="" id="aaa"> -->
  </div>
</template>
<script>
import "@google/model-viewer";
export default {
  components: {},
  data() {
    return {
      list: [
        require("@/assets/img/s.png"),
        require("@/assets/img/s.png"),
        require("@/assets/img/s.png"),
        require("@/assets/img/s.png"),
        require("@/assets/img/s.png"),
        require("@/assets/img/s.png"),
      ],
      textList: [
        { text: "A", id: "a" },
        { text: "B", id: "b" },
        { text: "C", id: "c" },
        { text: "D", id: "d" },
        { text: "E", id: "e" },
        { text: "F", id: "f" },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.createCanvas();
  },
  methods: {
    createCanvas() {
      const canvas = document.getElementById("canvas");
      if (canvas.getContext) {
        const ctx = canvas.getContext("2d");
        // ctx.fillStyle = 'rgb(200,0,0)'
        // ctx.fillRect(10,10,55,50)
        // ctx.fillStyle = 'rgba(0,0,200,0.5)'
        // ctx.fillRect(30,30,55,50)
        ctx.beginPath();
        // ctx.moveTo(100,100)
        // ctx.lineTo(100,100)
        // ctx.lineTo(50,100)
        // ctx.closePath()
        // ctx.stroke()
        // ctx.moveTo(50,110)
        // ctx.lineTo(100,110)
        // ctx.lineTo(100,160)
        // ctx.fill()
        // ctx.closePath()
        // ctx.stroke()
        // ctx.quadraticCurveTo(130,60,160,100)
        // ctx.quadraticCurveTo(190,60,220,100)
        // ctx.quadraticCurveTo(220,170,160,200)
        // ctx.quadraticCurveTo(100,170,100,100)
        // ctx.stroke()
        // ctx.rect(100,100,100,100)
        // ctx.rect(120,120,100,100)
        // ctx.rect(140,140,100,100)
        // ctx.stroke()
        // ctx.moveTo(50,110)
        // ctx.lineTo(150,110)
        // ctx.lineWidth = 10
        // ctx.lineCap = 'butt'
        // ctx.stroke()
        // const a1 = new Path2D()
        // a1.rect(10,10,100,100)
        // const a2 = new Path2D()
        // a2.arc(200,200,50,0,Math.PI * 2)
        // ctx.stroke(a1)
        // ctx.fill(a2)
        // const a3 = new Path2D(a1)
        // a3.arc(200,200,50,0,Math.PI * 2)
        // ctx.fill(a3)
        // ctx.fillStyle = "#FD0";
        // ctx.fillRect(0, 0, 75, 75);
        // ctx.fillStyle = "#6C0";
        // ctx.fillRect(75, 0, 75, 75);
        // ctx.fillStyle = "#09F";
        // ctx.fillRect(0, 75, 75, 75);
        // ctx.fillStyle = "#F30";
        // ctx.fillRect(75, 75, 75, 75);
        // ctx.fillStyle = "#FFF";
        // ctx.globalAlpha = 0.2;
        // for (var i = 0; i < 7; i++) {
        //   ctx.beginPath();
        //   ctx.arc(75, 75, 10 + 10 * i, 0, Math.PI * 2);
        //   ctx.fill();
        // }
        // ctx.setLineDash([10,5]);
        // ctx.moveTo(0, 50);
        // ctx.lineTo(300, 50);
        // ctx.stroke();
        // const gradient = ctx.createLinearGradient(0,0,100,0)
        // gradient.addColorStop(0,'green')
        // gradient.addColorStop(0.5,'yellow')
        // gradient.addColorStop(1,'orange')
        // ctx.fillStyle = gradient
        // ctx.fillRect(10,10,100,100)
        // ctx.strokeStyle = gradient
        // ctx.strokeRect(20,20,100,100)
        // var radgrad = ctx.createRadialGradient(45,45,10,52,50,30);
        // radgrad.addColorStop(0, '#A7D30C');
        // radgrad.addColorStop(0.9, '#019F62');
        // radgrad.addColorStop(1, 'rgba(1,159,98,0)');
        // ctx.fillStyle = radgrad;
        // ctx.fillRect(0,0,150,150);
        // var imga = document.getElementById('aaa')
        // console.log(imga);
        // const imgs = new Image()
        // imgs.src = this.$concatImg('img16')
        // imgs.onload = function(){
        //   ctx.drawImage(imgs,0,0,200,200,50,50,100,100);
        // }
        // ctx.font = "48px serif";
        // ctx.fillText('hello world',50,50)
        // const sss = ctx.measureText('hello world')
        // console.log(sss.width);
        ctx.fillRect(30,30,100,100)
        ctx.scale(2,2)
      }
    },
  },
};
</script>

<style lang="less">
.box2 {
}
.box1 {
  width: 100%;
  height: 100vh;
  position: relative;
  .right {
    position: fixed;
    right: 0;
    top: 0;
    width: 100px;
    background-color: #f1f1f1;
    .item {
      width: 100%;
      color: #232323;
      font-size: 20px;
      line-height: 60px;
      font-weight: 600;
      text-align: center;
      border-bottom: 1px solid #fff;
    }
  }
  .left {
    width: calc(100% - 100px);
    overflow-y: scroll;
    height: 100vh;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    .item {
      scroll-snap-align: start;
      scroll-snap-stop: always;
      height: 100vh;
      width: 100%;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 600;
    }
  }
}

.bor-box {
  padding: 30px 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  .over {
    width: 300px;
    height: auto;
    background-color: #fff;
    overflow: hidden;
    .flooe {
      // animation: testAni 10s linear 1s infinite;
      width: 900px;
      height: 100%;
      background-color: rgba(0, 128, 0, 0.658);
      letter-spacing: 50px;
      font-size: 30px;
      font-weight: bold;
    }
  }
  .bor {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border: 25px solid transparent;
    // border-image: url('~@/assets/img/img52.png') 25 round;
    border-image: linear-gradient(
        to right bottom,
        rgba(255, 0, 0, 0.363),
        rgba(255, 255, 0, 0.664)
      )
      2 round;
  }
  .bor1 {
    width: 200px;
    height: 200px;
    padding: 20px;
    border: 20px solid transparent;
    background-image: url(../../assets/img/s.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: border-box;
  }
}
.btn {
  width: 300px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  border-radius: 50px;
  position: relative;
  animation: btnAni 0.5s ease-out 1s;
  animation-fill-mode: backwards;
}
.btn:hover {
  transform: scale(1.01) translateY(-3px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.btn:active {
  transform: scale(1) translateY(-1px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.btn::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 50px;
  transition: all 0.3s;
}
.btn:hover::after {
  transform: scale(1.5);
  opacity: 0;
}
@keyframes btnAni {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.btn-box {
  padding: 30px 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.test-img {
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%);
}
.test {
  padding: 100px 0;
  .test1 {
    overflow-y: hidden;
    height: 300px;
    margin-bottom: 30px;
  }
  .test2 {
    width: 100%;
    // animation: testAni 10s linear 1s infinite;
    .test3 {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
@keyframes testAni {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>